.pre-checkin__container {
  max-width: 430px;
  margin: 0 auto;
  padding: 12px;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.pre-checkin__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.pre-checkin__preview-image {
  max-width: 100%;
  max-height: 200px;
  object-fit: contain;
}

.pre-checkin__error-message {
  color: #ff0000;
  font-size: 0.8em;
  margin-top: 5px;
}

.pre-checkin__title {
  width: 100%;
  padding: 10px 55px 0 0;
}

.pre-checkin__back-button {
  background: none;
  border: none;
  padding: 8px;
  cursor: pointer;
  margin-right: 16px;
}

.pre-checkin__main-content {
  padding: 0 4px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.pre-checkin__subtitle {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 24px;
}

.pre-checkin__id-upload-section {
  display: flex;
  gap: 16px;
  margin-bottom: 24px;
}

.pre-checkin__upload-card {
  flex: 1;
  background-color: #f0f3ff;
  border-radius: 12px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.pre-checkin__upload-card:hover {
  background-color: #e6ebff;
}

.pre-checkin__upload-title {
  font-size: 14px;
  color: #333;
  margin: 0;
}

.pre-checkin__upload-subtitle {
  font-size: 12px;
  color: #4361ee;
  text-align: center;
  margin: 0;
}

.pre-checkin__form-group {
  margin-bottom: 16px;
}

.pre-checkin__form-input {
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 12px;
  background-color: #f0f3ff;
  font-size: 16px;
  outline: none;
}

.pre-checkin__form-input::placeholder {
  color: #666;
}

.pre-checkin__acknowledgment {
  margin: 24px 0;
}

.pre-checkin__checkbox-container {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  cursor: pointer;
}

.pre-checkin__checkbox-container input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.pre-checkin__checkbox-text {
  font-size: 14px;
  color: #333;
  line-height: 1.4;
}

.pre-checkin__accept-link {
  color: #4361ee;
  text-decoration: none;
  font-size: 14px;
  display: block;
  margin-top: 8px;
  margin-left: 32px;
}

.pre-checkin__submit-button {
  width: 100%;
  padding: 16px;
  background-color: #4361ee;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: auto;
}

.pre-checkin__submit-button:hover:not(:disabled) {
  background-color: #3a54d6;
}

.pre-checkin__date-input-container {
  position: relative;
}

.pre-checkin__calendar-icon {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
}

.react-datepicker {
  font-family: Arial, sans-serif;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.react-datepicker__header {
  background-color: #f0f3ff;
  border-bottom: 1px solid #ccc;
}

.react-datepicker__day--selected {
  background-color: #4361ee;
  color: white;
}

.react-datepicker__day:hover {
  background-color: #e6ebff;
}

.react-datepicker__year-dropdown {
  max-height: 200px;
  overflow-y: auto;
}

@media (max-width: 380px) {
  .pre-checkin__id-upload-section {
    flex-direction: column;
  }

  .pre-checkin__upload-card {
    padding: 16px;
  }

  .pre-checkin__title h1 {
    font-size: 22px;
    font-weight: 600;
  }
}
