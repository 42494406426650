@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.upsell-container {
  min-height: 100vh;
  background-color: #ffffff;
  font-family: 'Poppins', sans-serif;
  padding-bottom: 100px;
}

.upsell-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #ffffff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  z-index: 50;
  padding: 0.75rem 1rem;
}

.upsell-header-content {
  max-width: 64rem;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.upsell-back-button {
  position: absolute;
  left: 0;
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
}

.upsell-title {
  font-size: 24px;
  font-weight: 600;
  color: #000;
  line-height: 36px;
  text-align: center;
}

.upsell-content {
  max-width: 64rem;
  margin: 0 auto;
  padding: 5rem 1rem 1.5rem;
}

.upsell-grid {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1rem;
}

.upsell-card {
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid #e5e7eb;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.upsell-card-content {
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative;
  min-height: 200px;
}

.upsell-card-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 0.5rem;
}

.upsell-card-title {
  font-size: 18px;
  font-weight: 600;
  color: #111827;
  margin: 0;
}

.upsell-card-price {
  font-size: 18px;
  font-weight: 600;
  color: #4361ee;
}

.upsell-card-description {
  color: #6b7280;
  font-size: 14px;
  line-height: 1.5;
  margin: 0;
}

.upsell-quantity-wrapper {
  margin-top: auto;
  padding-bottom: 3rem;
}

.upsell-quantity-selector {
  display: inline-flex;
  align-items: center;
  border: 1px solid #e5e7eb;
  border-radius: 24px;
  overflow: hidden;
  background: #f9fafb;
}

.upsell-quantity-selector.fixed {
  opacity: 0.7;
  pointer-events: none;
}

.upsell-quantity-button {
  background: none;
  border: none;
  color: #4b5563;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upsell-quantity-button:hover:not(:disabled) {
  background-color: #f3f4f6;
}

.upsell-quantity-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.upsell-quantity {
  padding: 0 16px;
  font-size: 16px;
  font-weight: 500;
  color: #111827;
  min-width: 40px;
  text-align: center;
}

.upsell-fixed-quantity-note {
  display: block;
  font-size: 12px;
  color: #6b7280;
  margin-top: 0.5rem;
}

.upsell-buy-button {
  position: absolute;
  bottom: 1.5rem;
  right: 1.5rem;
  background-color: #4361ee;
  color: white;
  border: none;
  border-radius: 24px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upsell-buy-button:hover:not(:disabled) {
  background-color: #3651d4;
}

.upsell-buy-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.upsell-empty {
  text-align: center;
  padding: 2rem;
  color: #6b7280;
}

.upsell-error {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  text-align: center;
  padding: 1rem;
}

.upsell-error-message {
  color: #ef4444;
  margin-bottom: 1rem;
}

.upsell-error-button {
  background-color: #4361ee;
  color: white;
  border: none;
  border-radius: 24px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.upsell-error-button:hover {
  background-color: #3651d4;
}

@media (max-width: 480px) {
  .upsell-header {
    padding: 1rem;
  }

  .upsell-title {
    font-size: 20px;
  }

  .upsell-grid {
    padding: 0.5rem;
  }

  .upsell-card-content {
    padding: 1rem;
  }
}

/* Add these new styles to the existing Upsell.css file */

.hcaptcha-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.hcaptcha-modal {
  background: white;
  padding: 2rem;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  text-align: center;
}

.hcaptcha-modal h2 {
  margin-bottom: 1.5rem;
  font-size: 1.25rem;
  font-weight: 600;
}

.hcaptcha-container {
  display: flex;
  justify-content: center;
  margin: 1rem 0;
}

.hcaptcha-error {
  color: #ef4444;
  margin-bottom: 1rem;
  font-size: 0.875rem;
  padding: 0.5rem;
  background-color: #fee2e2;
  border-radius: 0.375rem;
}

