.order-confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: white;
  padding: 2rem;
  text-align: center;
}

.order-confirmation-message {
  font-size: 1.2rem;
  color: black;
  margin-bottom: 1rem;
}

.order-confirmation-error {
  color: #e53e3e;
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.order-confirmation-button {
  background-color: #4981fd;
  color: white;
  border: none;
  border-radius: 0.375rem;
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s;
}

.order-confirmation-button:hover {
  background-color: #3a67d4;
}

