@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600&display=swap");

.unlocking-details-wrapper {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.container {
  flex: 1;
  padding: 20px;
  background-color: #fff;
  min-height: 100vh;
  font-family: Poppins;
  position: relative;
  padding-bottom: 80px;
}

.UnlockingDetailHeading {
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  margin: 10px 0 10px 0;
  color: #000;
}

.unlocking-hotel-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 24px;
  height: 250px;
}

.hotel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hotel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
}

.hotel-name {
  position: absolute;
  bottom: 16px;
  left: 16px;
  color: white;
  font-weight: 600;
  font-size: 24px;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.booking-section h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 16px 0;
  color: #333;
}

.details-card {
  background: white;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #eee;
}

.detail-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #d3d3d3;
  color: #666;
}

.detail-item:last-child {
  border-bottom: none;
}

.detail-item svg {
  margin-right: 12px;
  color: #666;
}

.detail-value {
  margin-left: auto;
  color: #4361ee !important;
  font-weight: 500;
}

.unlock-button-frontdoor {
  width: 100%;
  background-color: #4361ee;
  color: white;
  border: none;
  border-radius: 32px;
  padding: 16px 27px;
  margin-top: 20px;
  cursor: pointer;
  transition: background-color 0.2s;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
}

.unlockbtn {
  display: flex;
  flex-direction: column;
}

.unlock-button-frontdoor:hover {
  background-color: #1d4ed8;
}

.frontdoor-btn {
  padding: 0px 10px;
}

.room-list {
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  gap: 24px;
  padding: 0px 10px;
  margin-bottom: 70px;
}

.room-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #bebebe;
  border-radius: 50px;
  padding: 10px;
  color: #000;
}

.room-item-text {
  font-family: Poppins;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.room-item.disabled {
  /* opacity: 0.5; */
  cursor: not-allowed;
}

.room-item.disabled:hover {
  background-color: inherit;
}

.arrow {
  color: #000;
  font-weight: bold;
}

.unlocking-bottom-navigation {
  background-color: #3068fd;
  border-radius: 50px;
  margin: 24px 12px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  padding: 0.75rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.unlocking-bottom-navigation button {
  background: none;
  border: none;
  padding: 0.5rem;
}

.unlocking-bottom-navigation button img {
  width: 24px;
}

.unlock-message {
  color: #ff0000;
  margin-top: 10px;
  text-align: center;
}

@media (max-width: 480px) {
  .container {
    padding: 0.5rem;
  }

  .UnlockingDetailHeading {
    font-size: 21px;
    text-align: center;
    line-height: 36px;
  }

  .hotel-name {
    font-size: 16px;
  }

  .detail-item {
    font-size: 14px;
  }
}

@media (max-width: 380px) {
  .unlocking-hotel-card {
    height: 250px;
  }

  .room-list {
    gap: 10px;
  }

  .unlock-button-frontdoor {
    margin-top: 10px;
  }
}
.button-container {
  position: relative;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 20px;
}

.share-key-button {
  background-color: #4981fd;
  color: white;
  border: none;
  border-radius: 32px;
  padding: 14px 27px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.2s;
  font-family: Poppins;
  line-height: 20px;
}


.share-key-button:hover {
  background-color: #3068fd;
}