@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;600;700&display=swap");

.guest-login-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.guest-image-card {
  position: relative;
  width: 100%;
  height: 50vh;
  /* min-height: 300px; */
  overflow: hidden;
}

.guest-image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.guest-image-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1.5rem;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 0.7) 0%,
    rgba(0, 0, 0, 0) 100%
  );
}

.guest-text-content {
  color: white;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  line-height: 48px;
}

.guest-form-section {
  padding: 2rem 1rem;
}

.guest-input-group {
  position: relative;
  margin-bottom: 1.5rem;
}

.guest-input-group input {
  width: 100%;
  padding: 0.75rem 1rem 0.75rem 3rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  transition: border-color 0.3s ease;
}

.guest-input-group input:focus {
  outline: none;
  border-color: #007bff;
}

.guest-icon {
  position: absolute;
  top: 50%;
  left: 1rem;
  transform: translateY(-50%);
  color: #666;
  font-size: 1.25rem;
}

.guest-verify-container {
  margin-top: 1.5rem;
}

.guest-otp-label {
  display: block;
  margin-bottom: 1rem;
  font-family: Poppins;
  font-size: 14px;
  color: #3068fd;
  padding-left: 2rem;
}

.guest-otp-inputs {
  display: flex;
  justify-content: space-evenly;
  margin-bottom: 1.5rem;
}

.guest-otp-inputs input {
  width: 3rem;
  height: 3rem;
  text-align: center;
  font-size: 1.25rem;
  border: 1px solid #ccc;
  border-radius: 8px;
}

.guest-verify-button {
  width: 100%;
  padding: 0.75rem;
  font-size: 1rem;
  color: white;
  background-color: #3068fd;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-family: Poppins;
  font-weight: 700;
  line-height: 24px;
}

.guest-verify-button:hover {
  background-color: #3068fd;
}

.error-message {
  color: #ff0000;
  font-size: 14px;
  margin-top: 10px;
  text-align: center;
}

@media (min-width: 768px) {
  .guest-login-container {
    flex-direction: row;
  }

  .guest-image-card {
    width: 50%;
    height: 100vh;
  }

  .guest-form-section {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .guest-form-section form {
    width: 100%;
    max-width: 400px;
  }
}

@media (max-width: 380px) {
  .guest-text-content {
    font-size: 27px;
  }
}
