 .share-key__container {
  max-width: 430px;
  margin: 0 auto;
  padding: 12px;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.share-key__header {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.share-key__back-button {
  background: none;
  border: none;
  padding: 0px;
  cursor: pointer;
  margin-right: -2px;
}

.share-key__title {
  margin: 10px 0 10px 0;
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.share-key__form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.share-key__form-row {
  display: flex;
  gap: 18px;
}

.share-key__form-group {
  flex: 1;
}

.share-key__form-input {
  width: 100%;
  padding: 16px;
  border: none;
  border-radius: 12px;
  background-color: #f0f3ff;
  font-size: 16px;
  outline: none;
}

.share-key__form-input::placeholder {
  color: #666;
}

.share-key__phone-input {
  display: flex;
  gap: 8px;
}

.share-key__country-code {
  padding: 16px;
  border: none;
  border-radius: 12px;
  background-color: #f0f3ff;
  font-size: 16px;
  outline: none;
}

.share-key__phone-number {
  flex: 1;
}

.share-key__checkbox-group {
  display: flex;
  align-items: flex-start;
  gap: 12px;
}

.share-key__checkbox-group input[type="checkbox"] {
  width: 20px;
  height: 20px;
  margin-top: 2px;
}

.share-key__checkbox-group label {
  font-size: 14px;
  color: #333;
  line-height: 1.4;
}

.share-key__warning-message {
  color: #d32f2f;
  font-size: 14px;
  text-align: center;
  margin-top: 16px;
}

.share-key__submit-button {
  width: 100%;
  padding: 16px;
  background-color: #4361ee;
  color: white;
  border: none;
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  margin-top: 24px;
}

.share-key__submit-button:hover:not(:disabled) {
  background-color: #3a54d6;
}

.share-key__submit-button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.share-key__loading,
.share-key__error {
  text-align: center;
  margin-top: 20px;
  font-size: 18px;
}

@media (max-width: 380px) {
  .share-key__form-row {
    flex-direction: column;
  }

  .share-key__title {
    font-size: 22px;
  }
}


 