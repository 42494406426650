.order-confirmed-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #ffffff;
  font-family: 'Poppins', sans-serif;
}

.order-confirmed-header {
  display: flex;
  align-items: center;
  padding: 1rem;
  background-color: #ffffff; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-confirmed-header h1 {
  flex-grow: 1;
  text-align: center;
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.order-confirmed-back-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0.5rem;
}

.order-confirmed-content {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 2rem 1rem;
  text-align: center;
  padding-bottom: 220px;
}

.order-confirmed-icon {
  font-size: 4rem;
  color: #4CAF50;
  margin-bottom: 1.5rem;
}

.order-confirmed-message {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
}

.order-confirmed-reservation-id {
  font-size: 1rem;
  color: #666;
  margin-bottom: 2rem;
  max-width: 80%;
}

.order-confirmed-dashboard-button {
  background-color: #4981fd;
  color: white;
  border: none;
  border-radius: 50px;
  padding: 0.75rem 2rem;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.order-confirmed-dashboard-button:hover {
  background-color: #3a6ad4;
}

@media (max-width: 480px) {
  .order-confirmed-header h1 {
    font-size: 1.1rem;
  }

  .order-confirmed-message {
    font-size: 1.25rem;
  }

  .order-confirmed-reservation-id {
    font-size: 0.9rem;
  }

  .order-confirmed-dashboard-button {
    font-size: 0.9rem;
    padding: 0.6rem 1.5rem;
  }
}

