@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.gd-app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  /* padding: 0.5rem; */
  /* padding: 20px 8px 8px 8px; */
  padding: 12px 8px 70px 8px;
  gap: 2rem;
  /* gap: 0.5rem; */
  background-color: #f8f9fa;
  font-family: Poppins, sans-serif;
  font-weight: 400;
  overflow-x: hidden;
}

.gd-dashboard-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
}

.gd-profile-image {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #e9ecef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.gd-location-icon {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: #495057;
}

.gd-notification-icon {
  width: 2.5rem;
  height: 2.5rem;
  background-color: #e9ecef;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.gd-main-content {
  flex: 1;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch; /* For smoother scrolling on iOS */
}

.gd-welcome-card {
  height: 350px;
  position: relative;
  border-radius: 1rem;
  overflow: hidden;
  aspect-ratio: 16/9;
  background-size: cover;
  background-position: center;
}

.gd-welcome-card::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
}

.gd-welcome-content {
  position: relative;
  z-index: 2;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  padding: 1rem;
}

.gd-welcome-content h2 {
  font-size: 2rem;
  align-self: flex-start;
  margin-bottom: 1rem;
}

.gd-checkin-btn {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  text-align: center;
}

.gd-checkin-button {
  background-color: #4981fd;
  color: white;
  border: none;
  padding: 0.75rem 2rem;
  border-radius: 50px;
  font-size: 1rem;
  cursor: pointer;
}

.gd-account-name {
  font-size: 1.25rem;
  align-self: flex-end;
}

.gd-picks {
  margin-top: 20px;
  position: relative;
}

.gd-service-message {
  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
  white-space: nowrap;
  max-width: 90%;
  overflow: hidden;
  text-overflow: ellipsis;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}

.gd-pick-options {
  cursor: pointer;
  display: flex;
  overflow-x: auto;
  padding-bottom: 10px;
  -webkit-overflow-scrolling: touch;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.gd-pick-options::-webkit-scrollbar {
  display: none;
}

.gd-pick-option {
  flex: 0 0 auto;
  width: 180px;
  height: 180px;
  margin-right: 15px;
  position: relative;
  overflow: hidden;
  border-radius: 10px;
}

.gd-pick-option img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gd-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 10px;
}

.gd-overlay p {
  color: white;
  margin: 0;
  font-size: 14px;
}

.gd-bottom-navigation {
  background-color: #3068fd;
  border-radius: 50px;
  margin: 24px 12px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  padding: 1.25rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.gd-bottom-navigation button {
  background: none;
  border: none;
  cursor: pointer;
}

.gd-bottom-navigation button img {
  width: 24px;
}

.lucide-map-pin {
  color: #ff0000;
}

@media (max-width: 768px) {
  .gd-welcome-content h2 {
    font-size: 32px;
    font-family: Poppins;
    font-weight: 500;
  }

  .gd-checkin-button {
    padding: 1rem;
    font-size: 1rem;
  }

  .gd-account-name {
    font-size: 1.25rem;
    font-family: Poppins;
    font-weight: 500;
    line-height: 30px;
  }

  .gd-picks h3 {
    font-size: 1.5rem;
    margin-bottom: 16px;
  }

  .gd-overlay {
    padding: 0.5rem;
  }
}

@media (max-width: 400px) {
  .gd-welcome-card {
    height: 250px;
  }

  .gd-pick-option {
    width: 150px;
    height: 130px;
  }

  .gd-app-container {
    gap: 1.5rem;
  }

  .gd-account-name {
    font-size: 1rem;
    font-family: Poppins;
    font-weight: 500;
    line-height: 30px;
  }

  .gd-welcome-content h2 {
    font-size: 24px;
    font-family: Poppins;
    font-weight: 500;
  }

  .gd-checkin-btn p {
    font-family: Poppins;
    font-size: 13px;
    font-weight: 500;
  }

  .gd-checkin-button {
    padding: 14px;
    font-size: 14px;
  }
}

.gd-language-selector {
  position: relative;
}

.gd-language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
}

.gd-language-dropdown button {
  display: block;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  text-align: left;
  cursor: pointer;
}

.gd-language-dropdown button:hover {
  background-color: #f0f0f0;
}

/* Add these new styles for the language selector */
.gd-language-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
}

.gd-language-flag {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  object-fit: cover;
}

.gd-language-flag-small {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  object-fit: cover;
}

.gd-language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  background: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  margin-top: 8px;
}

.gd-language-dropdown button {
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
  padding: 8px 16px;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  white-space: nowrap;
}

.gd-language-dropdown button:hover {
  background-color: #f5f5f5;
}

/* Add toast message styles */
.toast-message {
  position: fixed;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: #e5e5e5;
  padding: 12px 24px;
  border-radius: 8px;
  font-size: 14px;
  z-index: 1000;
  animation: fadeInOut 3s forwards;
  white-space: nowrap;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  90% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

/* Remove disabled styles */
.gd-pick-option {
  position: relative;
  cursor: pointer;
  border-radius: 12px;
  overflow: hidden;
  transition: transform 0.2s;
}