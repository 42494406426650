@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.container {
  padding: 20px;
  background-color: #fff;
  min-height: 100vh;
  font-family: Poppins;
  position: relative;
  padding-bottom: 80px;
}

.bookingDetailHeading {
  margin: 10px 0 10px 0;
  color: #000;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
}

.booking-hotel-card {
  position: relative;
  border-radius: 20px;
  overflow: hidden;
  margin-bottom: 24px;
  height: 250px;
}

.hotel-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hotel-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.hotel-name {
  color: white;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin-bottom: 16px;
  text-align: center;
  font-size: 1rem;
  font-family: Poppins;
  font-weight: 500;
  line-height: 30px;
}

.booking-section h2 {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 16px 0;
  color: #333;
}

.booking-details-card {
  background: white;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  border: 1px solid #666;
}

.booking-detail-item {
  display: flex;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #666;
  color: #666;
}

.booking-detail-item:last-child {
  border-bottom: none;
}

.booking-detail-item svg {
  margin-right: 12px;
  color: #666;
}

.booking-detail-item span {
  color: #666;
}

.detail-value {
  margin-left: auto;
  color: #4361ee !important;
  font-weight: 500;
}

.booking-unlock-button {
  background-color: #4981fd;
  color: white;
  border: none;
  border-radius: 32px;
  padding: 14px 27px;
  font-size: 15px;
  font-weight: 400;
  cursor: pointer;
  transition: background-color 0.2s;
  line-height: 20px;
  font-family: Poppins;
}

.booking-bottom-navigation {
  background-color: #3068fd;
  border-radius: 50px;
  margin: 24px 12px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  padding: 0.75rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.booking-bottom-navigation button {
  background: none;
  border: none;
  padding: 0.5rem;
}

.booking-bottom-navigation button img {
  width: 24px;
}

@media (max-width: 480px) {
  .container {
    padding: 0.5rem;
  }

  .bookingDetailHeading {
    font-size: 21px;
    text-align: center;
    line-height: 36px;
  }

  .booking-detail-item {
    font-size: 14px;
  }
}
