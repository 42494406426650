.bottom-navigation {
  background-color: #3068fd;
  border-radius: 50px;
  /* margin: 12px; */
  margin: 12px 12px 8px 12px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  /* padding: 1rem; */
  padding: 7px;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.bottom-navigation button {
  background: none;
  border: none;
  cursor: pointer;
  position: relative;
  padding: 0.5rem;
}

.bottom-navigation button img {
  width: 24px;
}

.bottom-navigation button .active-line {
  display: none;
  position: absolute;
  bottom: -2px;
  left: 50%;
  transform: translateX(-50%);
  width: 24px;
  height: 2px;
  background-color: white;
  border-radius: 1px;
}

.bottom-navigation button.active .active-line {
  display: block;
}

.gd-service-message {
  position: fixed;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
  white-space: nowrap;
  max-width: 90%;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}

/* @media (max-width: 380px) {
  .bottom-navigation {
    padding: 0.5rem;
  }
} */
