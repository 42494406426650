.checkout-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .checkout-modal {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    width: 90%;
    max-width: 500px;
    max-height: 90vh;
    overflow-y: auto;
  }
  
  .checkout-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1.5rem;
  }
  
  .checkout-title {
    font-size: 1.25rem;
    font-weight: 600;
    margin: 0;
  }
  
  .checkout-close {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0.5rem;
  }
  
  .checkout-amount {
    font-size: 1.5rem;
    font-weight: 600;
    text-align: center;
    margin-bottom: 1.5rem;
    color: #4361ee;
  }
  
  #payment-form {
    width: 100%;
  }
  
  #payment-element {
    margin-bottom: 24px;
  }
  
  #submit {
    background: #4361ee;
    color: white;
    border: none;
    border-radius: 24px;
    padding: 12px 24px;
    font-size: 1rem;
    font-weight: 500;
    width: 100%;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  #submit:hover {
    background: #3651d4;
  }
  
  #submit:disabled {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  #payment-message {
    color: #ef4444;
    text-align: center;
    margin-top: 12px;
    font-size: 0.875rem;
  }
  
  .spinner {
    border: 3px solid rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    border-top: 3px solid white;
    width: 24px;
    height: 24px;
    animation: spin 1s linear infinite;
    margin: 0 auto;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  