* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: "Poppins", sans-serif;
  background: linear-gradient(180deg, #e9eaed 44.98%, #83a3f5 100%);
  min-height: 100vh;
}

.mobile-only-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  padding: 1rem;
  background-color: transparent;
}
