@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600&display=swap");

.helpContainer {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fff;
  font-family: Poppins;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 16px;
  position: relative;
}

.back-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  color: #333;
  position: absolute;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
}

h1 {
  margin: 0;
  color: #333;
  font-family: Poppins;
  font-size: 24px;
  font-weight: 600;
  line-height: 36px;
  width: 100%;
  text-align: center;
}

.email-support {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  padding: 24px 20px;
  text-align: center;
  margin: 24px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.email-support span {
  font-size: 16px;
  color: #333;
}

.helpContainer {
  position: relative;
  padding-bottom: 80px;
}

.helpContainer > :last-child {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.support-item {
  background-color: #fff;
  border: 1px solid #e0e0e0;
  border-radius: 50px;
  /* padding: 16px 20px; */
  padding: 16px 3px;
  text-align: left;
  /* margin: 16px 24px; */
  /* margin: 20px 16px; */
  margin: 16px 6px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.support-item:hover {
  background-color: #f5f5f5;
}

.support-item svg {
  margin-right: 12px;
  color: #333;
}

.support-item span {
  flex-grow: 1;
  font-size: 16px;
  color: #333;
}

.support-item svg:last-child {
  margin-left: 12px;
  cursor: pointer;
}

.support-item svg:last-child:hover {
  color: #007bff;
}

.notification {
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.7);
  color: white;
  padding: 10px 20px;
  border-radius: 20px;
  font-size: 14px;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  10%,
  90% {
    opacity: 1;
  }
}
