.unlock-room {
  background-color: #fff;
  height: 100vh;
}

.unlock-room-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 70vh;
  padding: 20px;
  background-color: #fff;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, sans-serif;
}

.header {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 20px 0 80px 12px;
  background-color: #fff;
}

.back-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-right: 10px;
}

h1 {
  font-size: 24px;
  font-weight: 600;
  margin: 0;
  flex-grow: 1;
  text-align: center;
}

.room-number {
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 50px;
  padding: 20px 20px;
  text-align: center;
  margin: 0 24px;
}

.lock-icon {
  margin: 20px 0;
}

.lock-icon img {
  width: 164px;
  height: 240px;
}

.unlock-button {
  background-color: #0056ff;
  color: white;
  border: none;
  border-radius: 25px;
  padding: 15px 30px;
  font-size: 18px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.unlock-button:hover:not(:disabled) {
  background-color: #0044cc;
}

.unlock-button.unlocked {
  background-color: #3068fd;
}

.unlock-button:disabled {
  /* opacity: 0.7; */
  cursor: not-allowed;
}

.unlockRoom-bottom-navigation {
  background-color: #3068fd;
  border-radius: 50px;
  margin: 24px 12px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-around;
  padding: 0.75rem;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.1);
}

.unlockRoom-bottom-navigation button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 5px;
}

.unlockRoom-bottom-navigation img {
  width: 24px;
}

.check-in-message {
  background-color: #f8d7da;
  color: #721c24;
  padding: 10px 15px;
  margin-top: 15px;
  border: 1px solid #f5c6cb;
  border-radius: 5px;
  font-size: 14px;
  text-align: center;
}
